





































import {Vue, Component, Prop} from 'vue-property-decorator'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import Badge from '@/components/proxy/Badge.vue'

type corner = 'top' | 'bottom' | 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left'

@Component({
  components: {Badge},
})
export default class NEOMButton extends Vue {
  @Prop({
    type: String,
    default: 'none',
  }) protected slant!: 'none' | 'wide' | 'square' | corner
  @Prop({
    type: String,
    default: 'transparent',
  }) protected color!: string
  @Prop({
    type: String,
    default: 'bottom-right',
  }) protected colorPosition!: corner
  @Prop({
    type: String,
    required: false,
  }) protected text?: string

  protected svgRef: SVGElement | null = null
  protected svgWidth: number = 0
  protected svgHeight: number = 0

  protected get parsedColor() {
    return VuetifyColorHelper.color(this.color)
  }

  protected get path() {
    // tslint:disable: cyclomatic-complexity // it's a switch-case ffs
    switch (this.slant) {
      case 'none':
        return 'M16.29 0h127.42s16.29 0 16.29 16.29v116.75s0 16.29 -16.29 16.29h-127.42s-16.29 0 -16.29 -16.29v-116.75s0 -16.29 16.29 -16.29'
      case 'top-left':
        return 'M16.01 149.33h144.25c8.84 0 16-7.16 16-16V16c-.01-8.84-7.17-16-16.01-16H46.88c-7.27 0-13.62 4.9-15.47 11.93L.54 129.26c-2.67 10.15 4.98 20.07 15.47 20.07Z'
      case 'top-right':
        return 'M160.25 149.33H16c-8.84 0-16-7.16-16-16V16C0 7.16 7.16 0 16 0h113.38c7.27 0 13.62 4.9 15.47 11.93l30.87 117.33c2.67 10.15-4.98 20.07-15.47 20.07Z'
      case 'top':
        return 'M32.43 11.14.51 130.83c-2.54 9.52 4.64 18.86 14.49 18.86h147.79c9.86 0 17.03-9.34 14.49-18.86L145.36 11.14A15 15 0 0 0 130.87 0H46.93c-6.8 0-12.74 4.57-14.49 11.14Z'
      case 'bottom-left':
        return 'M.53,19.58C-2.1,9.7,5.35,0,15.58,0h145.85c8.6,0,15.57,6.97,15.57,15.57v118.55c0,8.6-6.97,15.57-15.57,15.57H47.19c-7.05,0-13.23-4.74-15.05-11.56L.53,19.58Z'
      case 'bottom-right':
        return 'M144.85 138.13a15.57 15.57 0 0 1-15.05 11.56H15.57c-8.6 0-15.57-6.97-15.57-15.57V15.57C0 6.97 6.97 0 15.57 0h145.85c10.23 0 17.68 9.7 15.05 19.58l-31.61 118.55Z'
      case 'bottom':
        return 'M145.38 138.56 177.3 18.87C179.84 9.35 172.66.01 162.81.01H15.01C5.15 0-2.02 9.34.51 18.86l31.92 119.69a15 15 0 0 0 14.49 11.14h83.96c6.8 0 12.74-4.57 14.49-11.14Z'
      case 'wide':
        return 'M15 0h310s15 0 15 15v120s0 15 -15 15h-310s-15 0 -15 -15v-120s0 -15 15 -15'
      case 'square':
        return 'M15 0h140s15 0 15 15v140s0 15 -15 15h-140s-15 0 -15 -15v-140s0 -15 15 -15'
    }
  }

  protected get cPos() {
    let x = this.svgWidth ?? 177
    const y = this.svgHeight ?? 150
    switch (this.colorPosition) {
      case 'top':
        return {x: x / 2, y: 0}
      case 'bottom':
        return {x: x / 2, y}

      case 'top-left':
        x = ['top', 'top-left'].includes(this.slant) ? 48 : 0
        return {x, y: 0}
      case 'top-right':
        x = ['top', 'top-right'].includes(this.slant) ? x - 48 : x
        return {x, y: 0}
      case 'bottom-left':
        x = ['bottom', 'bottom-left'].includes(this.slant) ? 48 : 0
        return {x, y}
      case 'bottom-right':
      default: {
        x = ['bottom', 'bottom-right'].includes(this.slant) ? x - 48 : x
        return {x, y}
      }
    }
  }

  protected mounted() {
    this.svgRef = this.$refs.svg as SVGElement
    this.svgWidth = this.svgRef?.clientWidth
    this.svgHeight = this.svgRef?.clientHeight
  }
}
