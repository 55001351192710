
























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import {AxiosError} from 'axios'
import {LoginByPhoneRequest, LoginResponse} from '@/lib/kepler/interfaces'

@Component({
  components: {
    CloseButton: Utils.loadComponent('CloseButton'),
    Button: Utils.loadComponent('Button'),
    Digits: Utils.loadComponent('Digits'),
    Container: Utils.loadComponent('proxy/Container'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
  },
  name: 'PhoneVerification',
})
export default class PhoneVerification extends Vue {
  @Action('closeOverlays') public closeOverlays!: () => void
  @Action('loginByPhone') public loginByPhone!: (p: LoginByPhoneRequest) => Promise<LoginResponse>
  @Action('requestLoginByPhone') public requestLoginByPhone: any
  @Action('addProfilePicture') public addProfilePicture!: any
  @Action('loginByPhoneQuick') public loginByPhoneQuick!: (p: LoginByPhoneRequest) => Promise<LoginResponse>

  @Prop({
    type: [String, Number],
    required: true,
  }) protected mobileNumber!: string
  @Prop() protected profileAttachment!: string
  @Prop({
    type: Boolean,
    default: false,
  }) protected isDialog!: boolean
  @Prop({
    type: Function,
  }) protected callback!: (r: LoginResponse) => void
  @Prop({
    type: Boolean,
    default: false,
  }) protected quick!: boolean
  @Prop({
    type: String,
    default: null,
  }) protected title!: string | null

  protected loading: boolean = false
  protected formValid: boolean = false
  protected alertMessage: string = ''
  protected digits!: string
  protected errorMessages: string[] = []

  protected completed(val: string) {
    this.formValid = true
    this.digits = val
  }

  protected resendVerification() {
    this.alertMessage = 'Message sent'

    this.requestLoginByPhone({mobile_number: this.mobileNumber}).then(() => {
      this.alertMessage = ''
    })
  }

  protected verify() {
    if (!this.loading) {
      this.loading = true

      if (!this.quick) {
        this.loginByPhone({mobile_number: this.mobileNumber.toString(), token: this.digits}).then((r) => {
          if (this.callback) {
            this.callback(r)
          } else {
            this.closeOverlays()
            this.$router.push({name: 'home'})
          }
        }).catch((e: AxiosError) => {
            if (e.response) {
              const data = e.response.data
              if (data.result_code === 'exceptions.auth.invalid-contact-verification-exception') {
                this.errorMessages.push(data.result)
              }
              this.loading = false
            }
          },
        )
      } else {
        this.loginByPhoneQuick({mobile_number: this.mobileNumber.toString(), token: this.digits}).then((r) => {
          if (this.callback) {
            this.callback(r)
          } else {
            this.closeOverlays()
          }
        }).catch((e: AxiosError) => {
            if (e.response) {
              const data = e.response.data
              if (data.result_code === 'exceptions.auth.invalid-contact-verification-exception') {
                this.errorMessages.push(data.result)
              }
            }
          },
        ).finally(() => {
          this.loading = false
        })
      }
    }
  }
}
