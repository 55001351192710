import Vue from 'vue'
import {ActionTree, GetterTree, MutationTree} from 'vuex'

import sdk from '@/lib/kepler/sdk'
import {
  AvailabilityRequest,
  BookingMesh,
  BookingMode,
  BookingRequest,
  BookRequest,
  EstimateRequest,
  ExtraActionRequest,
  OpenVehicleRequest,
  PaginatedRequest,
  RatingRequest,
  ReservationEditRequest,
  ReservationExtendRequest,
  ReservationFlag,
  ReservationPaginatedRequest,
  ReservationPaginatedResponse,
  ReservationResponse,
  TerminateRequest,
  VehicleCategory,
  VehicleSlot,
} from '@/lib/kepler/interfaces'
import {RootState, RS} from '@/store'
import {AxiosError} from 'axios'
import moment from 'moment'

export class Layer {
  public bookingMode: string
  public key: string
  public vehicles: VehicleSlot[]

  constructor(bookingMode: string, vehicles: VehicleSlot[], key: string) {
    this.bookingMode = bookingMode
    this.vehicles = vehicles
    this.key = key
  }
}

export class ReservationPaginatedClass implements ReservationPaginatedResponse {
  public current_page: number
  public current_page_count: number
  public items_count: number
  public page_count: number
  public per_page_count: number
  public items: ReservationResponse[]
  public loading: boolean

  constructor() {
    this.current_page = -1
    this.current_page_count = -1
    this.items_count = -1
    this.page_count = -1
    this.per_page_count = -1
    this.items = []
    this.loading = false
  }
}

export class BookingState {
  public layers: { [key: string]: Layer } = {}
  public activeReservations: ReservationResponse[] = []
  public terminateMode: boolean = false
  public terminateModeMessages: string[] = []
  public meshes!: BookingMesh[]
  public bookingFlags: ReservationFlag[] = []
  public categories!: VehicleCategory[]

  public reservations: ReservationPaginatedResponse = new ReservationPaginatedClass()
  public driversReservations: ReservationPaginatedResponse = new ReservationPaginatedClass()
  public futureReservations: ReservationResponse[] = []
  public unlockedReservations: Array<string | number> = []
  public lastBookingByMode: Partial<Record<BookingMode, number>> = {}
  public autoOpenPlate: string | null = null
}

let lastFutureReq: number = 0
let lastCurrentReq: number = 0

let pool: NodeJS.Timeout

const mutations: MutationTree<BookingState> = {
  FLUSH(state) {
    state.layers = {}
    state.activeReservations = []
    // state.meshes = []
    state.bookingFlags = []
    state.reservations = new ReservationPaginatedClass()
    state.driversReservations = new ReservationPaginatedClass()
    state.futureReservations = []
    state.lastBookingByMode = {}
  },
  BOOKING_LAYERS_FLUSH(state) {
    Vue.delete(state, 'layers')
    Vue.set(state, 'layers', {})
  },
  BOOKING_SET_ACTIVE_RESERVATIONS(state, payload: ReservationResponse[]) {
    lastCurrentReq = Math.floor(Date.now() / 1000)
    state.activeReservations = payload
  },
  BOOKING_SET_MESHES(state, payload: BookingMesh[]) {
    state.meshes = payload
  },
  BOOKING_SET_CATEGORIES(state, payload: VehicleCategory[]) {
    state.categories = payload
  },
  BOOKING_TERMINATE_MODE_ON(state, payload: string[]) {
    state.terminateModeMessages = payload
  },
  BOOKING_TERMINATE_MODE_OFF(state) {
    state.terminateMode = false
  },
  BOOKING_SET_FLAG(state, payload: ReservationFlag) {
    const flag: ReservationFlag = {
      reservation_number: payload.reservation_number,
      value: payload.value,
      flag: 'DONT_CLOSE_BEFORE',
    }
    state.bookingFlags.push(flag)
  },
  BOOKING_FLUSH_FLAGS(state) {
    state.bookingFlags = []
  },
  BOOKING_RESERVATIONS(state, payload: ReservationPaginatedResponse) {
    state.reservations.current_page = payload.current_page
    state.reservations.current_page_count = payload.current_page_count
    payload.items.forEach((item) => {
      const duplicate = state.reservations.items.findIndex((i) => i.id === item.id)
      if (duplicate >= 0) {
        state.reservations.items[duplicate] = item
      } else {
        state.reservations.items.push(item)
      }
    })

    state.reservations.items_count = payload.items_count
    state.reservations.page_count = payload.page_count
    state.reservations.per_page_count = payload.per_page_count
  },
  BOOKING_RESERVATIONS_FLUSH(state) {
    const loading = state.reservations.loading
    state.reservations = new ReservationPaginatedClass()
    state.reservations.loading = loading
  },
  BOOKING_FUTURE_RESERVATIONS(state, payload: ReservationResponse[]) {
    lastFutureReq = Math.floor(Date.now() / 1000)
    state.futureReservations = payload
  },
  BOOKING_DRIVERS_RESERVATIONS(state, payload: ReservationPaginatedResponse) {
    state.driversReservations.current_page = payload.current_page
    state.driversReservations.current_page_count = payload.current_page_count
    payload.items.forEach((item) => {
      state.driversReservations.items.push(item)
    })

    state.driversReservations.items_count = payload.items_count
    state.driversReservations.page_count = payload.page_count
    state.driversReservations.per_page_count = payload.per_page_count
  },
  BOOKING_DRIVERS_RESERVATIONS_FLUSH(state) {
    state.driversReservations = new ReservationPaginatedClass()
  },
  OPEN_VEHICLE_IN_RESERVATION_ADD(state, resnum: number) {
    if (typeof state.unlockedReservations !== 'object') {
      Vue.set(state, 'unlockedReservations', [])
    }
    state.unlockedReservations.push(resnum)
    if (state.unlockedReservations.length > 10) {
      state.unlockedReservations.shift()
    }
  },
  SET_LAST_BOOKING_BY_MODE(state, mode: BookingMode) {
    Vue.set(state.lastBookingByMode, mode, Math.floor(Date.now() / 1000))
  },
  SET_AUTO_OPEN_PLATE(state, plate?: string) {
    state.autoOpenPlate = plate ?? null
  },
  SET_RESERVATIONS_LOADING(state, v: boolean) {
    state.reservations.loading = v
  },
}

const actions: ActionTree<BookingState, RootState> = {
  init({dispatch}) {
    dispatch('serviceMeshes')
    dispatch('categories')
    dispatch('current')
    dispatch('terminateModeOff')
  },
  layersFLush({commit}) {
    return commit('BOOKING_LAYERS_FLUSH')
  },
  book({dispatch, commit}, payload: BookRequest) {
    return sdk.booking.book(payload).then((r) => {
      dispatch('setZone', r.data.vehicle_slot.id)
      dispatch('refreshHistory')
      dispatch('_getVehicleSlots')
      dispatch('sleep', 'book')
      commit('SET_LAST_BOOKING_BY_MODE', r.data.vehicle_slot.reservation_type)
      return r.data
    })
  },
  // status.booking.sent
  purge({commit, dispatch}) {
    dispatch('layersFLush')
    commit('BOOKING_DRIVERS_RESERVATIONS_FLUSH')
    return commit('FLUSH')
  },
  current({rootState, state, dispatch, commit}): Promise<ReservationResponse[]> {
    const now = Math.floor(Date.now() / 1000)
    if (now - lastCurrentReq > 5) {
      if (rootState.userToken) {
        return sdk.booking.current().then((r) => {
          const reservations = r.data
          if (state.activeReservations.length !== reservations.length) {
            dispatch('reservationHistory')
            dispatch('bookingRequestHistory')
          }
          commit('BOOKING_SET_ACTIVE_RESERVATIONS', reservations)
          reservations.forEach((reservation) => {
            dispatch('setZone', reservation.vehicle_slot.id)
          })
          dispatch('sleep', 'current')
          dispatch('currentRefresh', reservations.length ? 10 : 60)
          return r.data
        }).catch(() => {
          return dispatch('currentRefresh', 5)
        })
      } else {
        return dispatch('currentRefresh')
      }
    } else {
      return Promise.resolve(state.activeReservations)
    }
  },
  currentRefresh({dispatch}, s = 60) {
    clearTimeout(pool)
    pool = setTimeout(() => {
      dispatch('current')
    }, s * 1000)
  },
  openVehicle({dispatch}, payload: OpenVehicleRequest) {
    dispatch('refreshHistory')
    return sdk.booking.open(payload)
  },
  closeVehicle({}, payload: ReservationResponse) {
    return sdk.booking.close({reservation_number: payload.number})
  },
  getReservationByNumber({}, reservationNumber: number) {
    return sdk.booking.getByNumber(reservationNumber).then((r) => r.data)
  },
  serviceMeshes({dispatch, commit}) {
    return sdk.booking.serviceMesh().then((r) => {
      commit('BOOKING_SET_MESHES', r.data)
      dispatch('sleep', 'service_meshes')
    })
  },
  zones({}, id: string) {
    return sdk.booking.zones(id).then((r) => r.data)
  },
  terminateModeOn({commit}, payload: string[]) {
    commit('BOOKING_TERMINATE_MODE_ON', payload)
  },
  terminateModeOff({commit}) {
    commit('BOOKING_TERMINATE_MODE_OFF')
  },
  terminateReservation({dispatch}, payload: TerminateRequest) {
    return new Promise((resolve, reject) => {
      sdk.booking.terminate(payload)
        .then((response) => {
          dispatch('refreshHistory')
          dispatch('_getVehicleSlots')
          resolve(response.data)
        }, (error: AxiosError) => {
          // here could be dragons
          if (error.response !== undefined) {
            if (error.response.status === 409) {
              const message: [string] = error.response.data.messages
              dispatch('terminateModeOn', message)
            }
          }
          reject(error)
        })
    })
  },
  bookingFlags({commit}, payload: ReservationFlag) {
    commit('BOOKING_SET_FLAG', payload)
  },
  bookingFlushFlags({dispatch, commit}) {
    commit('BOOKING_FLUSH_FLAGS')
    dispatch('sleep', 'booking_flush_flags')
  },
  categories({dispatch, commit}) {
    return sdk.booking.categories().then((r) => {
      commit('BOOKING_SET_CATEGORIES', r.data)
      dispatch('sleep', 'categories')
    })
  },
  flushAndReloadHistory({dispatch, commit, state}) {
    commit('SET_RESERVATIONS_LOADING', true)
    commit('BOOKING_RESERVATIONS_FLUSH')
    commit('BOOKING_FUTURE_RESERVATIONS', [])
    dispatch('futureReservations')
    return dispatch('reservationHistory')
  },
  futureReservations({commit}) {
    return sdk.reservation.get({per_page: -1, page_number: 1, preset: 'upcoming'})
      .then(({data}) => {
        commit('BOOKING_FUTURE_RESERVATIONS', data.items)
      })
  },
  deleteReservation({dispatch}, payload: ReservationResponse) {
    return sdk.booking.deleteReservation(payload).then(() => {
      return dispatch('refreshHistory')
    })
  },
  editReservation({dispatch}, payload: ReservationEditRequest) {
    return sdk.booking.editReservation(payload).then((r) => {
      dispatch('refreshHistory')
      return r.data
    })
  },
  extendReservation({dispatch}, payload: ReservationExtendRequest) {
    return sdk.booking.extendReservation(payload).then((data) => {
      dispatch('refreshHistory')
      return data.data
    })
  },
  vehicleAvailability({}, payload: AvailabilityRequest) {
    return sdk.booking.availabilty(payload).then((r) => {
      r.data.forEach((a) => {
        Object.assign(a, {selected: false})
      })
      return r.data
    })
  },
  bookEstimate({}, payload: EstimateRequest) {
    return sdk.booking.estimate(payload).then((r) => {
      return r.data
    })
  },
  refreshHistory({dispatch, commit}) {
    commit('BOOKING_RESERVATIONS_FLUSH')
    commit('BOOKING_FUTURE_RESERVATIONS', [])
    dispatch('current')
    dispatch('reservationHistory')
    dispatch('futureReservations')
  },
  getFuelPin({}, reservationNumber: number) {
    return sdk.booking.getFuelPin(reservationNumber).then((r) => r.data)
  },
  reservationHistory({commit, state}, payload: ReservationPaginatedRequest = {
    per_page: 10, page_number: 1, preset: 'past',
  }): Promise<ReservationPaginatedResponse> {
    return new Promise((resolve) => {
      sdk.reservation.get(payload)
        .then((response) => {
          commit('BOOKING_RESERVATIONS', response.data)
          resolve(response.data)
        })
        .finally(() => {
          commit('SET_RESERVATIONS_LOADING', false)
        })
    })
  },
  myDriversReservations({commit}, payload: PaginatedRequest = {per_page: 10, page_number: 1}): Promise<ReservationPaginatedResponse> {
    return new Promise((resolve) => {
      sdk.people.myDriversReservations(payload).then((response) => {
        commit('BOOKING_DRIVERS_RESERVATIONS', response.data)
        resolve(response.data)
      })
    })
  },
  flushAndReloadDriversReservationsHistory({dispatch, commit}): Promise<ReservationPaginatedResponse> {
    commit('BOOKING_DRIVERS_RESERVATIONS_FLUSH')
    return dispatch('myDriversReservations')
  },
  bookingRating({}, payload: RatingRequest) {
    const {ratings, reservation} = payload
    return sdk.booking.rating(reservation, ratings)
  },
  hasOpenedVehicleInReservation({state, commit}, payload: { resnum: number, add?: boolean }) {
    if (payload.add) {
      commit('OPEN_VEHICLE_IN_RESERVATION_ADD', payload.resnum)
    }
    return Promise.resolve(state.unlockedReservations?.includes(payload.resnum))
  },
  sendExtraAction({}, payload: ExtraActionRequest) {
    return sdk.reservation.sendCommand(payload.reservationNumber, payload.operation, payload.data).then((r) => r.data)
  },
  checkupReservation({}, resNum: number) {
    return sdk.reservation.checkup(resNum).then((r) => r.data)
  },
  setAutoOpenPlate({commit}, plate?: string) {
    commit('SET_AUTO_OPEN_PLATE', plate)
  },
  updateNeomHistory({dispatch}) {
    dispatch('flushAndReloadHistory')
    dispatch('bookingRequestHistory', {page_number: 1, per_page: -1})
  },
}

const getters: GetterTree<BookingState, RootState> = {
  remindBookingModes: (state, {remindBookingModesThreshold}) => {
    const bag: Partial<Record<keyof typeof BookingMode, boolean>> = {}
    const modes = Object.keys(BookingMode) as Array<keyof typeof BookingMode>
    const threshold: number | undefined = remindBookingModesThreshold

    for (const mode of modes) {
      if (typeof threshold === 'number' && threshold >= 0) {
        const ts = state.lastBookingByMode[mode]
        if (typeof ts === 'number') {
          const reminderEnd = moment.unix(ts).add(threshold, 'days')
          const today = moment()
          bag[mode] = reminderEnd.isSameOrBefore(today)
        } else {
          bag[mode] = true
        }
      } else {
        bag[mode] = false // no threshold, disabled
      }
    }
    return bag
  },
  activeReservations: ({activeReservations}) => activeReservations,
  monoMode: ({meshes}) => meshes.length <= 1,
  bm: ({meshes}) => {
    const s: Set<string> = new Set()
    meshes.forEach((m) => s.add(m.booking_mode))
    return Array.from(s)
  },
  vt: ({meshes}) => {
    const s: Set<string> = new Set()
    meshes.forEach((m) => s.add(m.vehicle_type))
    return Array.from(s)
  },
  futureReservations: (state) => state.futureReservations,
  mesh: ({meshes}) => meshes,
  autoOpenPlate: ({autoOpenPlate}) => autoOpenPlate,
  reservationLoading: ({reservations}) => reservations.loading,
  neomHistory: (s, g, rs) => {
    const root = rs as RS
    const requests = root.bookingRequests.items
      .filter((br) => !br.reservation) // Only ones without a reservation
      .sort((a, b) => a.start_timestamp - b.start_timestamp)
    const futureReservations = s.futureReservations || []

    const active = s.activeReservations
    const past = s.reservations.items
    const upcoming: Array<BookingRequest | ReservationResponse> = requests
    upcoming.push(...futureReservations)
    const loading = root.bookingRequests.loading || s.reservations.loading

    return {active, past, upcoming, loading}
  },
}

export default {
  state: new BookingState(),
  mutations,
  actions,
  getters,
}
