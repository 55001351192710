





















import {Component, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import {ResetPasswordRequest} from '@/lib/kepler/interfaces'
import ConfirmDialog from '../views/ConfirmDialog.vue'
import {EventBus} from '@/main'
import Utils from '@/utils'
import {AxiosPromise} from 'axios'

@Component({
  name: 'ResetPassword',
  components: {
    Button: Utils.loadComponent('Button'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Layout: Utils.loadComponent('proxy/Layout'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Container: Utils.loadComponent('proxy/Container'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
  },
})
export default class ResetPassword extends Vue {
  @Action('resetPassword') public resetPassword!: (payload: ResetPasswordRequest) => AxiosPromise

  protected resetRequest: ResetPasswordRequest = {identification: null}
  protected loading: boolean = false

  protected mounted() {
    EventBus.$on('ResetPasswordSent', () => {
      this.$popup.close()
      this.$dialog.close(0)
    })
  }

  protected destroyed() {
    EventBus.$off('ResetPasswordSent')
  }

  protected sendResetPassword(e: any) {
    this.loading = true
    this.resetPassword(this.resetRequest).then(() => {
      this.$dialog.open(ConfirmDialog, {
        props: {
          imageState: 'success.svg',
          code: '',
          subtitle: this.$t('login.confirm_reset'),
          singleAction: true,
          title: '',
          confirmText: this.$t('action.close'),
        }, hideTopbar: false, confirmedEvent: 'ResetPasswordSent',
      })
    }).finally(() => {
      this.loading = false
    })
    e.preventDefault()
  }
}
