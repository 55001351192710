









import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Button: Utils.loadComponent('Button'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
  },
  name: 'EmptyList',
})
export default class EmptyList extends Vue {
  @Prop({type: String, default: () => ''}) public color?: string
  @Prop({type: Boolean, default: false}) public noBg?: boolean
  @Prop({type: Boolean, default: true}) public fill?: boolean
  @Prop({type: Boolean, default: false}) public loading?: boolean
}
