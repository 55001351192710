























import {Component} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {Action, Getter, State} from 'vuex-class'
import Utils from '@/utils'
import TabHistoryMixin from '@/lib/TabHistory'
import {BookingRequest, ReservationPaginatedResponse, ReservationResponse} from '@/lib/kepler/interfaces'
import CardReservation from '@/components/entities/reservation/CardReservation.vue'
import BookingRequestCard from '@/components/entities/reservation/BookingRequestCard.vue'
import {TopbarButton} from '@/store/modules/topbar'
import HistoryBookingDetail from '@/views/Booking/HistoryBookingDetail.vue'
import DateHelper from '@/lib/DateHelper'

interface NeomHistory {
  active: ReservationResponse[]
  past: ReservationResponse[]
  upcoming: Array<ReservationResponse | BookingRequest>
  loading: boolean
}

@Component({
  components: {
    EmptyList: Utils.loadComponent('EmptyList'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Tabs: Utils.loadComponent('proxy/Tabs/Tabs'),
    Tab: Utils.loadComponent('proxy/Tabs/Tab'),
    TabItems: Utils.loadComponent('proxy/Tabs/TabItems'),
    TabItem: Utils.loadComponent('proxy/Tabs/TabItem'),
    FullListHistory: Utils.loadView('Booking/FullListHistory'),
  },
  mixins: [TabHistoryMixin],
})
export default class History extends mixins<TabHistoryMixin>(TabHistoryMixin) {
  @State((state) => state.booking.reservations) public reservations!: ReservationPaginatedResponse

  @Getter('neomHistory') public nh!: NeomHistory
  @Getter('activeReservations') public activeReservations!: ReservationResponse[]

  @Action('setActionButtons') public setActionButtons!: (buttons: TopbarButton[]) => void
  @Action('updateNeomHistory') public updateNeomHistory!: () => void

  public currentTab: number | null = null
  public tabMap: { [key: string]: number } = {
    '#upcoming': 0,
    '#past': 1,
  }

  public mounted() {
    const buttons = [{
      icon: 'mdi-refresh',
      action: this.updateNeomHistory,
    },
    ]
    this.$nextTick(() => {
      this.setActionButtons(buttons)
    })
  }

  protected getComponent(i: NeomHistory['upcoming'][0]) {
    if ('vehicle_slot' in i) {
      return CardReservation
    } else {
      return BookingRequestCard
    }
  }

  protected getComponentProps(i: NeomHistory['upcoming'][0]) {
    if ('vehicle_slot' in i) {
      return {reservation: i, future: true}
    } else {
      return {request: i}
    }
  }

  protected action(i: NeomHistory['upcoming'][0]) {
    if ('vehicle_slot' in i) {
      const res = i
      if (this.isFuture(res)) {
        this.$popup.open(HistoryBookingDetail, {
          props: {state: 'future', res},
          title: this.$t('booking.reservation', {number: res.number}),
        })
      } else {
        this.$router.push({name: 'reservation', params: {id: res.id}})
      }
    }
  }

  protected isFuture(res: ReservationResponse) {
    const now = DateHelper.getTimestamp()
    const start = res.start_timestamp || 0
    const end = res.end_timestamp || 0
    const current: boolean = start && end ? now > start && end >= now : false
    return !this.activeReservations.find((a) => a.id === res.id) && !current
  }

  protected created() {
    this.updateNeomHistory()
  }
}
