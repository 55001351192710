






































































































import {Vue, Component} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {ProfileState} from '@/store/modules/profile'
import Validations from '../lib/Validations'
import {EventBus} from '@/main'

import ConfirmDialog from '../views/ConfirmDialog.vue'
import Utils from '@/utils'
import PaymentMethodHelper from '@/lib/PaymentMethodHelper'
import {AvailablePaymentMethod, Card, Wallet} from '@/lib/kepler/interfaces'

@Component({
  components: {
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardText: Utils.loadComponent('proxy/Card/CardText'),
    Img: Utils.loadComponent('proxy/Image'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    InputDatePicker: Utils.loadComponent('proxy/Inputs/InputDatePicker'),
    DatePicker: Utils.loadComponent('DatePicker'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'AddCardDialog',
})
export default class AddCardDialog extends Vue {
  @State('profile') public profileState!: ProfileState
  @Action('addPaymentMethod') public addPaymentMethod!: (newCard: Partial<Card>) => Promise<Card>
  @Action('getPaymentMethods') public getPaymentMethods!: () => Promise<void>
  @Action('getPaymentMethodUrl') public getPaymentMethodUrl: any
  @Action('getAvailablePaymentMethods') public getAvailable!: () => Promise<AvailablePaymentMethod[]>
  @Action('getWallets') public getWallets!: () => Promise<Wallet[]>

  public rules = Validations.rules
  protected valid: boolean = false
  protected loading: boolean = false
  protected initialLoading: boolean = false
  protected newCard: Partial<Card> = {
    name: undefined,
    number: undefined,
    expiration_date: undefined,
    cvv: undefined,
  }
  protected availablePaymentMethods: AvailablePaymentMethod[] = []
  protected selectedPaymentMethod: AvailablePaymentMethod | null = null
  protected selectedPaymentMethodUrl: string = ''
  protected inAppBrowserRef: any = null
  protected statusMessage: string = ''

  protected get clientName() {
    return this.profileState.client ? this.profileState.client.name : ''
  }

  protected get nextFifty() {
    const d = new Date()
    d.setFullYear(d.getFullYear() + 50)
    return d.toISOString()
  }

  protected get cardBrand() {
    if (this.newCard.number) {
      return PaymentMethodHelper.getBrand(this.newCard.number)
    }
  }

  protected created() {
    this.initialLoading = true
    this.getAvailable().then((r: AvailablePaymentMethod[]) => {
      this.availablePaymentMethods = r
      if (r.length === 1) {
        this.selectedPaymentMethod = r[0]
      }
      this.initialLoading = false
    })
  }

  protected openBrowser(method: string) {
    this.selectedPaymentMethodUrl = ''
    this.loading = true
    this.getPaymentMethodUrl(this.selectedPaymentMethod?.provider).then((url: string) => {
      if (method === 'url-external') {
        cordova.InAppBrowser.open(url, '_system')
        this.$dialog.close()
      } else {
        const options = `location=yes,hidden=yes,usewkwebview=yes,zoom=no,hideurlbar=yes,hidenavigationbuttons=yes,
  footer=no,toolbarcolor=#ffffff,closebuttoncolor=${this.$branding.theme.secondary}`
        // for custom text instead of close button "X" add
        // closebuttoncaption=` + $t('some.translation.string')

        document.addEventListener('deviceready', () => {
          this.inAppBrowserRef = cordova.InAppBrowser.open(url, '_blank', options) as any

          this.inAppBrowserRef.addEventListener('loadstop', () => {
            this.inAppBrowserRef.show()
          })

          this.inAppBrowserRef.addEventListener('exit', () => {
            this.getPaymentMethods().then(() => {
              this.loading = false
              this.inAppBrowserRef.close()
              EventBus.$emit('paymentMethodAdded')
              // this.$router.push({name: 'wallet', hash: '#payment-methods'})
              this.$dialog.close()
            })
          })
        })
      }
    })
  }

  protected beforeDestroy() {
    if (this.inAppBrowserRef) {
      delete (window as Partial<Window>).open
      this.inAppBrowserRef.close()
      this.inAppBrowserRef = null
    }
  }

  protected openConfirmation() {
    EventBus.$on('dialogConfirm', () => {
      this.getPaymentMethods().then(() => {
        EventBus.$emit('paymentMethodAdded')
        // this.$router.push({name: 'wallet', hash: '#payment-methods'})
        this.$dialog.close()
      })
    })
    this.$dialog.open(ConfirmDialog, {
      props: {
        code: '',
        title: this.$t('profile.wallet.addCard.confirmation.title'),
        subtitle: this.$t('profile.wallet.addCard.confirmation.subtitle'),
        confirmText: this.$t('profile.wallet.addCard.confirmation.button'),
        confirmColor: 'accent',
        imageState: 'icons/Card-big.svg',
        singleAction: true,
      },
    })
  }

  protected formatDate: (date: string) => string = (date) => {
    const [year, month] = date.split('-')
    return `${month}/${year}`
  }

  protected setExpiration(expiration: string) {
    this.newCard.expiration_date = this.formatDate(expiration)
    this.validate()
  }

  protected validate() {
    const form = (this.$refs.cardForm as any)
    const empty = !Object.values(this.newCard).some((v) => !!v)
    if (form && !empty) {
      form.validate()
    }
  }

  protected saveCard() {
    if (!this.loading) {
      this.loading = true
      this.validate()
      if (this.valid) {
        this.addPaymentMethod(this.newCard).then(() => {
          this.openConfirmation()
          this.loading = false
        }, () => {
          this.loading = false
        })
      }
    }
  }
}
