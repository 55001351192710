import {ActionTree, GetterTree, MutationTree} from 'vuex'
import {RootState} from '@/store'
import sdk from '@/lib/kepler/sdk'
import {Notification} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

export const NotificationState: Notification[] = []

const mutations: MutationTree<typeof NotificationState> = {
  SET_NOTIFICATIONS(state, payload: Notification[]) {
    payload.forEach((n) => state.push(n))
  },
  FLUSH_NOTIFICATIONS(state) {
    Utils.flushArray(state)
  },
}

const actions: ActionTree<typeof NotificationState, RootState> = {
  init({rootState, dispatch}) {
    if (rootState.userToken) {
      dispatch('getNotifications')
    }
  },
  async getNotifications({commit}) {
    const {data} = await sdk.profile.notifications.get_all()
    commit('FLUSH_NOTIFICATIONS')
    commit('SET_NOTIFICATIONS', data)
  },
  async notificationDelete({dispatch}, id: string) {
    await sdk.profile.notifications.delete(id)
    return await dispatch('getNotifications')
  },
  async notificationDeleteAll({dispatch}) {
    await sdk.profile.notifications.delete_all()
    return await dispatch('getNotifications')
  },
  async notificationRead({dispatch}, id: string) {
    await sdk.profile.notifications.mark_as_read(id)
    return await dispatch('getNotifications')
  },
  async notificationReadAll({dispatch}) {
    await sdk.profile.notifications.mark_all_as_read()
    return await dispatch('getNotifications')
  },
}

const getters: GetterTree<typeof NotificationState, RootState> = {
  hasNotifications: (state) => state.length,
  hasUnreadNotifications: (state, {unreadNotifications}) => unreadNotifications.length,
  unreadNotifications: (state) => state.filter((n) => !n.read),
}

export default {
  state: NotificationState,
  mutations,
  actions,
  getters,
}
